.selection-list {
    .selection-list-wrapper {
        height: 36px;
        margin: 0 0 20px 0;

        .selection-list-panel {
            &.fixed {
                position: fixed;
                width: 100%;
                left: 0;
                top: 0;
                background-color: $white-color;
                box-shadow: 0px 13px 33px #00000024;
                z-index: $base-z-index + 20;
                padding: 10px 0;

                .selection-list-panel-content {
                    max-width: 792px;
                    margin: 0 auto;
                    padding: 0 20px;

                    button,
                    a {
                        span {
                            display: none;

                            @include respond-above(sm) {
                                display: inline;
                            }
                        }
                    }

                    .selection-list-left {
                        padding: 0 0 0 10px;

                        .form-element {
                            button {
                                width: 42px;
                                text-align: center;

                                span {
                                    display: none;
                                }

                                i {
                                    margin: -3px 0 0 0;
                                }
                            }
                        }
                    }

                    .selection-list-right {
                        a {
                            width: 42px;
                            text-align: center;

                            i {
                                margin: -3px 0 0 0;
                            }

                            span {
                                display: none;
                            }
                        }
                    }
                }
            }

            .selection-list-panel-content {
                display: flex;
                justify-content: space-between;

                .custom-checkbox {
                    display: inline-block;
                    margin: -4px 10px 0 0;
                    vertical-align: middle;

                    input[type=checkbox] + label {
                        padding: 0 0 0 17px !important;
                    }
                }

                .selection-list-left {
                    .form-element {
                        margin: 0;

                        button {
                            margin: 0 5px 0 0;
                            padding: 7px 5px;
                            width: 36px;

                            @include respond-above(sm) {
                                margin: 0 10px 0 0;
                                padding: 7px 10px;
                                width: auto;
                            }

                            i {
                                margin: -3px 0 0 0;

                                @include respond-above(sm) {
                                    margin: -3px 8px 0 0;
                                }
                            }
                        }
                    }
                }

                button,
                a {
                    margin: 0;
                    display: inline-block;
                    vertical-align: middle;
                    padding: 0;
                    border: 1px solid $base-border-color;
                    border-radius: $base-border-radius;
                    background-color: $white-color;
                    padding: 7px 10px;
                    font-size: $base-font-size;
                    color: $base-font-color;
                    text-decoration: none;
                    text-align: center;
                    @include transition-with-prefix('border-color', '200ms');
                    @include transition-with-prefix('box-shadow', '200ms');

                    &:hover {
                        box-shadow: 0px 13px 33px rgba(0, 0, 0, .1411764706);
                        border-color: $white-color;
                    }

                    @include respond-above(sm) {
                        text-align: left;
                    }

                    i {
                        font-size: $base-font-size + 2;
                        display: inline-block;
                        vertical-align: middle;
                        margin: -3px 8px 0 0;


                    }
                }

                button {
                    &:disabled {
                        opacity: 0.35;
                        cursor: default;

                        &:hover {
                            box-shadow: none;
                            border-color: $base-border-color;
                        }
                    }

                    span {
                        display: none;

                        @include respond-above(sm) {
                            display: inline;
                        }
                    }
                }
            }
        }
    }

    .selection {
        margin: 0 0 40px 0;

        &.opened {
            .selection-header {
                button {
                    i {
                        transform: rotate(180deg);
                    }
                }
            }

            .selection-content {
                display: block;
            }
        }

        .selection-header {
            display: flex;
            justify-content: space-between;
            background-color: $grey-light-color;
            padding: 15px 10px 14px 14px;
            border-bottom: 1px solid $base-border-color;

            .selection-header-left {
                width: 70%;

                button {
                    margin: 0 5px 0 0;
                    padding: 0;
                    border: 0;
                    background-color: transparent;
                    font-size: $base-font-size + 2;
                    font-weight: $font-weight-medium;

                    @include respond-above(md) {
                        font-size: $base-font-size + 4;
                    }

                    i {
                        font-size: $base-font-size - 8;
                        display: inline-block;
                        vertical-align: middle;
                        margin: -3px 5px 0 0;

                        @include respond-above(md) {
                            margin: -3px 14px 0 0;
                            font-size: $base-font-size - 6;
                        }
                    }
                }

                span {
                    display: inline-block;
                    font-size: $base-font-size;
                    color: $grey-color;
                }
            }

            .selection-header-right {
                width: 30%;
                text-align: right;

                .custom-select {
                    height: 20px;
                    position: relative;

                    select {
                        position: absolute;
                        text-align: right;
                        left: 0;
                        top: 0;
                        z-index: $base-z-index;
                        appearance: none;
                        background-color: transparent;
                        padding: 0 15px 0 0;
                        outline: none;
                        width: 100%;
                        color: $base-font-color;
                        cursor: pointer;
                        border: 0;
                        font-size: $base-font-size;
                        font-weight: $font-weight-medium;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    &::after {
                        font-size: $base-font-size - 8;
                        content: "\e900";
                        font-family: "icomoon";
                        position: absolute;
                        right: 0;
                        top: 1px;
                    }
                }
            }
        }

        .selection-content {
            display: none;

            .selection-empty {
                padding: 15px 10px 0 10px;
            }

            .selection-row {
                display: flex;
                flex-wrap: wrap;
                padding: 15px 0 12px 0;
                border-bottom: 1px solid $base-border-color;
                align-items: baseline;
                position: relative;

                &.new {
                    .left-col {
                        .name-col {
                            .btn-name {
                                font-weight: $font-weight-medium;
                            }
                        }
                    }

                    .right-col {
                        font-weight: $font-weight-medium;
                    }
                }

                &.disabled::before {
                    position: absolute;
                    width: 100%;
                    left: 0;
                    top: 0;
                    height: 100%;
                    background-color: #FAFAFA;
                    opacity: 0.6;
                    content: "";
                    z-index: $base-z-index;
                }

                &.opened {
                    padding: 15px 0 0 0;

                    .left-col {
                        .name-col {
                            .btn-name {
                                font-weight: $font-weight-medium;
                            }
                        }
                    }

                    .selection-row-content {
                        display: block;
                    }
                }

                .left-col {
                    display: flex;
                    flex-wrap: wrap;
                    width: 70%;
                    padding: 0 10px 0 0;
                    order: 1;

                    @include respond-above(md) {
                        width: 50%;
                    }

                    .checkbox-col {
                        width: 40px;
                        text-align: center;
                        position: relative;
                        z-index: $base-z-index + 5;

                        .form-element {
                            margin: 0;

                            .custom-checkbox {
                                display: inline-block;
                                vertical-align: middle;
                                margin: -4px 0 0 0;

                                input[type=checkbox] + label {
                                    padding: 0 0 0 17px !important;
                                }
                            }
                        }
                    }

                    .outline-col {
                        width: 32px;

                        button {
                            margin: 0;
                            padding: 0;
                            width: 22px;
                            height: 20px;
                            background-color: transparent;
                            border: 0;
                            position: relative;
                            z-index: $base-z-index - 5;


                            .icon-top,
                            .icon-topoutline {
                                font-size: $base-font-size + 6;
                            }

                            .icon-top {
                                color: $yellow-color;
                            }

                            .icon-topoutline {
                                color: #b7b7b7;
                            }

                            &:hover {
                                span {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }

                            span {
                                position: absolute;
                                background-color: $yellow-color;
                                color: $base-font-color;
                                border-radius: $base-border-radius;
                                height: 29px;
                                line-height: 29px;
                                padding: 0 8px;
                                font-size: $base-font-size - 2;
                                left: -6px;
                                top: -38px;
                                width: 212px;
                                opacity: 0;
                                visibility: hidden;
                                font-weight: $font-weight-medium;
                                @include transition-with-prefix('all', '200ms');

                                &::after {
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-left: 7px solid transparent;
                                    border-right: 7px solid transparent;
                                    border-top: 7px solid $yellow-color;
                                    position: absolute;
                                    left: 10px;
                                    bottom: -7px;
                                }
                            }
                        }
                    }

                    .name-col {
                        width: calc(100% - 72px);

                        .btn-mail {
                            margin: 0;
                            padding: 0;
                            border: 0;
                            background-color: transparent;
                            color: $base-color;
                            vertical-align: middle;
                            display: inline-block;

                            i {
                                font-size: $base-font-size + 2;
                                display: inline-block;
                                vertical-align: middle;
                                margin: -3px 0 0 0;
                            }
                        }

                        .btn-name {
                            margin: 0 10px 0 0;
                            display: inline-block;
                            padding: 0;
                            background-color: transparent;
                            border: 0;
                            text-align: left;

                            &.active {
                                font-weight: $font-weight-medium;
                            }
                        }
                    }
                }

                .middle-col {
                    width: 100%;
                    order: 3;
                    padding: 5px 10px 0 70px;

                    &.empty {
                        display: none;

                        @include respond-above(md) {
                            display: block;
                        }
                    }

                    @include respond-above(md) {
                        order: 2;
                        width: 25%;
                        padding: 0 10px 0 0;
                    }

                    .date {
                        color: $grey-color;
                    }
                }

                .right-col {
                    width: 30%;
                    order: 2;
                    padding: 0 10px 0 0;
                    text-align: right;

                    @include respond-above(md) {
                        width: 25%;
                        order: 3;
                    }

                    button {
                        margin: 0 0 0 10px;
                        padding: 0;
                        border: 0;
                        background-color: transparent;
                        color: #b7b7b7;
                        font-weight: $font-weight-normal;
                        @include transition-with-prefix('color', '200ms');

                        &:hover {
                            color: $base-font-color;
                        }

                        i {
                            display: inline-block;
                            vertical-align: middle;
                            font-size: $base-font-size + 6;
                            font-weight: $font-weight-normal;

                        }

                        img {
                            display: inline-block;
                            vertical-align: middle;
                            height: auto;
                            width: 20px
                        }
                    }
                }

                .note-col {
                    width: 100%;
                    order: 4;
                    text-align: left;
                    padding: 10px 0 0 0;

                    .note-text {
                        display: inline-block;
                        border-radius: $base-border-radius;
                        background-color: #f5f5f5;
                        padding: 5px 8px 4px 8px;
                        font-size: $base-font-size - 2;
                        font-style: italic;
                        margin: 0 0 3px 0;
                    }
                }

                .selection-row-content {
                    display: none;
                    padding: 15px 10px 0 10px;
                    order: 4;

                    .selection-info-note {
                        padding: 0;
                        margin: 0 0 10px 0;

                    }

                    .selection-row-info {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;

                        .selection-info-left {
                            width: 60px;
                            padding: 0 10px 0 0;
                            margin: 0 0 20px 0;
                            font-weight: $font-weight-medium;

                            &.pt4 {
                                padding: 3px 0 0 0;
                            }

                            &.pt8 {
                                padding: 8px 0 0 0;
                            }
                        }

                        .selection-info-right {
                            margin: 0 0 20px 0;
                            width: calc(100% - 60px);

                            a[href^="tel:"] {
                                color: $base-font-color;
                                text-decoration: none;
                            }

                            a[href^="mailto:"] {
                                color: $base-color;
                                text-decoration: none;
                            }

                            a[href^="mailto:"] i {
                                color: $base-font-color;
                                margin: 0 0 0 5px;
                            }

                            strong {
                                font-weight: $font-weight-medium;
                            }

                            .attachment {
                                padding: 0;
                                margin: 0;
                                border-bottom: 0;
                                display: inline-block;

                                .attachment-wrapper {
                                    max-width: none;
                                    padding: 0 10px 0 0;
                                }
                            }
                        }

                        .selection-action {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            .selection-action-left {
                                width: 100%;
                                margin: 0 0 10px 0;

                                @include respond-above(sm) {
                                    width: 194px;
                                    margin: 0;
                                }

                                .form-element {
                                    margin: 0;

                                    .custom-select {
                                        height: 36px;

                                        select {
                                            height: 36px;
                                        }

                                        &::after {
                                            top: 9px;
                                        }
                                    }
                                }
                            }

                            .selection-action-right {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;

                                @include respond-above(sm) {
                                    width: calc(100% - 194px);
                                    text-align: right;
                                    display: block;
                                }

                                button {
                                    height: 36px;
                                    line-height: 36px;
                                    padding: 0 14px;
                                    color: $white-color;
                                    background-color: $base-color;
                                    border-radius: $base-border-radius;
                                    border: 0;
                                    font-weight: $font-weight-medium;
                                    display: inline-block;
                                    vertical-align: top;
                                    @include transition-with-prefix('all', '200ms');

                                    &:hover {
                                        background-color: $base-hover-color;
                                    }

                                    text-align: center;
                                    width: 48%;

                                    @include respond-above(sm) {
                                        width: auto;
                                        text-align: left;
                                        margin: 0 0 0 10px;
                                    }
                                }

                                a {
                                    display: inline-block;
                                    vertical-align: top;
                                    border-radius: $base-border-radius;
                                    border: 1px solid $base-border-color;
                                    height: 36px;
                                    line-height: 34px;
                                    padding: 0 14px;
                                    font-weight: $font-weight-medium;
                                    color: $base-font-color;
                                    text-decoration: none;
                                    width: 48%;
                                    text-align: center;

                                    @include respond-above(sm) {
                                        width: auto;
                                        text-align: left;
                                        margin: 0 0 0 10px;
                                    }
                                }
                            }
                        }
                    }

                }

                .selection-info-note {
                    order: 4;
                    width: 100%;
                    padding: 0 10px;

                    .textarea-info-note {
                        display: inline-block;


                        .textarea-info-note-content {
                            display: grid;

                            &::after {
                                content: attr(data-replicated-value) " ";
                                white-space: pre-wrap;
                                visibility: hidden;
                            }
                        }

                        .textarea-info-note-content > textarea {
                            resize: none;
                            overflow: hidden;

                            @include placeholder {
                                color: $base-font-color;
                            }
                        }

                        .textarea-info-note-content > textarea,
                        .textarea-info-note-content::after {
                            font: inherit;
                            grid-area: 1 / 1 / 2 / 2;
                            background-color: $grey-light-color;
                            border: 0;
                            border-radius: $base-border-radius;
                            color: $base-font-color;
                            outline: none;
                            font-style: italic;
                            font-size: $base-font-size - 2;
                            margin: 10px 0 0 0;
                            padding: 5px 8px;
                            min-width: 138px;
                            min-height: 29px;
                            box-sizing: border-box;

                            @include transition-with-prefix('width', '200ms');


                        }
                    }
                }

                .selection-info-message {
                    margin: 0 0 20px 0;

                    .message-text {
                        box-shadow: 0px 13px 33px #00000024;
                        border-radius: $base-border-radius;
                        background-color: $white-color;
                        padding: 20px;
                        margin: 0 0 20px 0;

                        strong {
                            font-weight: $font-weight-medium;
                        }
                    }

                    .more-message {
                        text-align: center;

                        button {
                            margin: 0;
                            padding: 0;
                            border: 0;
                            background-color: transparent;
                            color: $base-color;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}
