.attachment {
    border-bottom: 1px solid $base-border-color;
    padding: 0 0 30px 0;
    margin: 0 0 40px 0;
    
    &:last-of-type {
        border-bottom: 0;
        margin: 0;
    }

    h2 {
        text-align: left !important;
        margin: 0 0 20px 0 !important;
    }

    p {
        color: #5d5d5d;
        margin: 0 0 20px 0;
    }

    .btn {
        margin: 0 !important;
    }

    .box-white-form {
        padding: 20px;
        margin: 0 0 20px 0;
    }

    .attachment-name {
        white-space: nowrap;
        text-decoration: none;

    }

    .attachment-preview {
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        border-radius: $base-border-radius;
        margin: 0 10px 0 0;

        img {
            width: 30px;
            display: block;
            border-radius: $base-border-radius;
            height: 30px;
            object-fit: cover;
            object-position: 50% 0%;
        }
    }

    .attachment-icon {
        width: 30px;
        height: 30px;
        line-height: 28px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        border-radius: $base-border-radius;
        border: 1px solid #b7b7b7;
        background-color: $white-color;
        color: $base-font-color;
        font-size: $base-font-size - 4;
        margin: 0 10px 0 0;
        font-weight: $font-weight-medium;
        &:hover {
            text-decoration: none;
        }

    }

    .attachment-wrapper {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $base-color;
        height: 30px;
        margin: 0 0 5px 0;

        @include respond-above(sm) {
            max-width: calc(100% - 80px);
            display: inline-block;
            vertical-align: middle;
            padding: 0 5px 0 0;
            margin: 0;
        }
    }

    .attachment-date {
        width: 80px;

        @include respond-above(sm) {
            display: inline-block;
            vertical-align: middle;
            margin: -2px 0 0 0;
        }
    }


    .box-white-form {

        .box-white-form-cv-right {
            padding: 4px 0 0 0;
        }
    }
}
